import { Controller } from "stimulus";
import { Popover } from "bootstrap";

export default class extends Controller {
  static targets = [
    "popover"
  ]

  connect() {
    this.popoverTargets.forEach((element) => {
      const _popover = new Popover(element, {
        html: true,
        content: element.dataset.content,
        sanitize: false,
        placement: 'top',
        template: '<div class="popover" role="tooltip" style="max-width: 400px; width: 100%;"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
      });
    });
  }
}